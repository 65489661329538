var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"login-card"},[_c('div',[_vm._m(0),_c('div',{staticClass:"login-main"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"pills":"","vertical":""}},[_c('b-card-text',[_c('form',{staticClass:"theme-form",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('h4',[_vm._v("Sign in to account")]),_c('p',[_vm._v("Enter your email & password to login")]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Email Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && !_vm.email
                          },attrs:{"type":"email","required":"","placeholder":"Test@gmail.com"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitted && !_vm.email),expression:"submitted && !email"}],staticClass:"invalid-feedback"},[_vm._v("Email is required")])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Password")]),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && !_vm.email
                          },attrs:{"autocomplete":"","name":"login[password]","required":"","placeholder":"*********","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && !_vm.email
                          },attrs:{"autocomplete":"","name":"login[password]","required":"","placeholder":"*********","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && !_vm.email
                          },attrs:{"autocomplete":"","name":"login[password]","required":"","placeholder":"*********","type":_vm.type},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitted && !_vm.password),expression:"submitted && !password"}],staticClass:"invalid-feedback"},[_vm._v("Email is required")]),_c('div',{staticClass:"show-hide",on:{"click":_vm.showPassword}},[_c('span',{staticClass:"show"})])]),_c('div',{staticClass:"form-group mb-0"},[_c('button',{staticClass:"btn mt-3 btn-primary btn-block",attrs:{"type":"submit"}},[_vm._v("Login")])])])])],1)],1)],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{staticClass:"logo"},[_c('img',{staticClass:"img-fluid for-light",staticStyle:{"max-height":"200px"},attrs:{"src":require("../assets/images/logo-RCA.png"),"alt":"looginpage"}}),_c('img',{staticClass:"img-fluid for-dark",staticStyle:{"max-height":"200px"},attrs:{"src":require("../assets/images/logo-RCA.png"),"alt":"looginpage"}})])])
}]

export { render, staticRenderFns }