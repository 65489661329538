var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-wrapper",class:_vm.layout.settings.sidebar.type == 'horizontal_sidebar'
        ? 'horizontal-wrapper'
        : 'compact-wrapper'},[_c('div',{staticClass:"page-header",class:{ close_icon: !_vm.togglesidebar }},[_c('Header',{on:{"clicked":_vm.sidebar_toggle}})],1),_c('div',{staticClass:"page-body-wrapper",class:_vm.layout.settings.sidebar.type == 'horizontal_sidebar'
          ? 'horizontal-menu'
          : 'sidebar-icon'},[_c('div',{staticClass:"sidebar-wrapper",class:[
          { close_icon: !_vm.togglesidebar },
          _vm.layout.settings.sidebar_backround,
        ],attrs:{"sidebar-layout":_vm.layout.settings.sidebar_setting}},[_c('Sidebar',{on:{"clicked":_vm.sidebar_toggle}})],1),_c('div',{staticClass:"page-body"},[_c('transition',{attrs:{"name":"fadeIn","enter-active-class":"animated fadeIn"}},[_c('router-view',{staticClass:"view"})],1)],1),_c('Footer')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }