<template>
  <div>
    <Breadcrumbs title="Aircrafts" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <b-row>
                <b-col md="6">
                  <b-input-group class="datatable-btn w-100">
                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col md="6" class="d-flex justify-content-end">
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                </b-col>
              </b-row>
            </div>
            <div class="card-body pt-0">
              <div class="table-responsive">
                <b-table
                  @row-clicked="rowClicked"
                  hover
                  style="cursor: pointer"
                  :busy="loading"
                  :items="items"
                  :sort-by.sync="sortBy"
                  :fields="tablefields"
                  :filter="filter"
                  :current-page="currentPage"
                  :tbody-tr-class="rowClass"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(name)="row">
                    <div class="d-flex align-items-center">
                      <!-- Add the image here -->
                      <img :src="getImageUrl(row)" alt="Aircraft Image" width="30" height="30" style="margin-right: 10px" />
                      <span>{{ row.value }}</span>
                    </div>
                  </template>

                  <template #cell(healthStatus)="data">
                    <div>
                      <span :class="getSpanForHealthStatus(data.item.healthStatus)"></span>
                    </div>
                  </template>
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </div>
              <b-col md="6">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
  import moment from "moment";
  import defaultAirImage from "../assets/images/Air-craft-1.png";
  export default {
    data() {
      return {
        maxskill: 100,
        loading: false,
        sortBy: "name",
        tablefields: [
          {
            key: "index",
            label: "#"
          },
          { key: "name", label: "Aircraft", sortable: true },
          { key: "healthStatus", label: "Health", sortable: true },
          {
            key: "aircraft_serial_number",
            label: "MSN",
            sortable: true
          },
          { key: "type", label: "Recorder Part No" },
          { key: "number", label: "Recorder Serial No" },
          {
            key: "aircraft_type",
            label: "Type",
            sortable: true
          }
        ],
        items: [],
        filter: null,
        totalRows: 0,
        currentPage: 1,
        perPage: 20
      };
    },
    computed: {
      sortOptions() {
        return this.tablefields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      }
    },
    mounted() {
      this.fetchFlights();
    },
    methods: {
      rowClass(item, type) {
        if (!item || type !== "row") return;

        if (item.hasNewFile) {
          return "table-warning";
        }
      },
      getImageUrl(row) {
        // Assuming row.image contains the URL of the image
        return row.item.image ? row.item.image : "../assets/images/Air-craft.png";
      },
      rowClicked(data) {
        return this.$router.push("aircrafts/files/" + data.id);
      },
      getClassForHealthStatus(value) {
        if (value == 1) {
          return "Good";
        }
        if (value == 2) {
          return "Average";
        }
        if (value == 3) {
          return "Bad";
        }
      },
      getSpanForHealthStatus(value) {
        if (value == 1) {
          return "dot bg-success ml-2";
        }
        if (value == 2) {
          return "dot bg-warning ml-2";
        }
        if (value == 3) {
          return "dot bg-danger ml-2";
        }

        return "dot bg-success ml-2";
      },
      fetchFlights() {
        this.loading = true;
        this.$axios
          .get("aircrafts?users_permissions_users=" + this.$store.state.auth.user.id)
          .then((result) => {
            const data = result.data;
            this.totalRows = data.length;
            this.items = data.map((flight) => {
              const imageUrl =
                flight.air_image && flight.air_image.length > 0 ? this.$axios.defaults.baseURL + flight.air_image[0].url : defaultAirImage;
              return {
                id: flight.id,
                name: flight.name,
                aircraft_serial_number: flight.aircraft_serial_number,
                updated_at: moment(flight.updated_at).format("DD/MM/YYYY h:mm a"),
                aircraft_disabled: flight.aircraft_disabled,
                aircraft_type: flight.aircraft_type,
                healthStatus: flight.healthStatus,
                type: flight.recorder_part_no ? flight.recorder_part_no : "--",
                number: flight.recorder_serial_no ? flight.recorder_serial_no : "--",
                image: imageUrl,
                hasNewFile: flight.hasNewFile
              };
            });
          })
          .catch((err) => {
            if (err.response.data.message.length) {
              console.log(err.response.data.message.length && err.response.data.message[0].messages);
              return this.$toasted.show(err.response.data.message[0].messages[0].message, {
                theme: "outline",
                position: "top-right",
                type: "default",
                duration: 4000
              });
            }
            this.$toasted.show("Something went wrong", {
              theme: "outline",
              position: "top-right",
              type: "default",
              duration: 4000
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      }
    }
  };
</script>
<style>
  .disabledclass,
  .disabledclass > td {
    background-color: rgb(105, 105, 105, 0.3);
    opacity: 55%;
  }

  .Good {
    background-color: #007f00;
  }

  .Average {
    background-color: #d8971a;
  }

  .Bad {
    background-color: #ff0000;
  }
</style>
