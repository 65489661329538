<template>
  <div>
    <div>
      <b-modal size="xl" title="Edit Report" v-model="isOpened" centered body-class="reportModal">
        <div class="card-body pt-0">
          <!-- <div class="table-responsive"> -->
          <b-table hover :items="filteredTableData" :fields="tablefields">
            <template #cell(index)="data">
              <div class="d-flex">
                <input type="checkbox" v-model="data.item.selected" @change="toggleSelection(data.index)" />
                {{ data.index + 1 }}
              </div>
            </template>
            <template #cell(Remarks)="data">
              <div>
                <input v-model="data.item.Remarks" />
              </div>
            </template>
          </b-table>
          <!-- </div> -->
        </div>
        <template #modal-footer="props">
          <div class="row">
            <div class="col">
              <div class="form-group mb-0">
                <button @click="props.cancel" class="btn btn-secondary mr-3">Cancel</button>
                <button @click="pop()" class="btn btn-danger mr-3" :disabled="selectedItems.length === 0 || loading">
                  Delete <b-spinner v-if="loading" small label="Loading"></b-spinner>
                </button>
                <button class="btn btn-primary mr-3" @click="updateFile()">Update</button>
              </div>
            </div>
          </div>
        </template>
      </b-modal>
    </div>
    <deleteModal ref="deleteModal" :deleterows="deleteRows" />
  </div>
</template>

<script>
  import deleteModal from "./delete_modal.vue";

  export default {
    data() {
      return {
        isOpened: false,
        loading: false,
        selectedItems: [],
        fileName: "",
        reportId: null,
        tablefields: [
          { key: "index", label: "#" },
          { key: "A/C Tail", label: "A/C Tail" },
          { key: "Flight #", label: "Flight" },
          { key: "Flight Date (MM/DD/YYYY)", label: "Flight Date" },
          //{ key: "Flight File (For Reference)", label: "Flight File (For Reference)" },
          { key: "Departure", label: "Departure" },
          { key: "Arrival", label: "Arrival" },
          { key: "Event Name", label: "Event Name" },
          { key: "Duration", label: "Duration" },
          { key: "SC", label: "SC" },
          { key: "Flight Phase", label: "Flight Phase" },
          { key: "Location", label: "Location" },
          { key: "Comment", label: "Comment" },
          { key: "Parameter", label: "Parameter" },
          { key: "Min", label: "Min" },
          { key: "Max", label: "Max" },
          { key: "Average", label: "Average" },
          { key: "Remarks", label: "Remarks" }
        ],
        tableData: []
      };
    },
    components: {
      deleteModal
    },
    props: {
      fileId: {
        type: Number,
        required: true
      }
    },
    computed: {
      filteredTableData() {
        return this.tableData.filter((item) => item.SC && item.SC !== "-");
      }
    },
    methods: {
      open(data) {
        this.data = {};
        if (data) {
          this.data = data;
          this.isOpened = true;
          this.callApi(this.data.id);
        }
      },
      async callApi(id) {
        this.$axios.post("reports/getProducts", { id: id }).then((res) => {
          const data = res.data;
          this.reportId = id;
          this.fileName = data.fileName;
          this.tableData = data.payload.map((result) => {
            return {
              "A/C Tail": result[`A/C Tail`] ? result[`A/C Tail`] : "",
              "Flight #": result[`Flight #`] ? result[`Flight #`] : "",
              "Flight Date (MM/DD/YYYY)": result[`Flight Date (MM/DD/YYYY)`] ?? result[`Flight Date** (MM/DD/YYYY)`] ?? "",
              "Flight File (For Reference)":
                result[`Flight File (For Reference)`] ?? result[`Analysed File (For reference)`] ?? result["Analysed File (For Reference)"] ?? "",
              Departure: result[`Departure`] ? result[`Departure`] : "",
              Arrival: result[`Arrival`] ? result[`Arrival`] : "",
              "Event Name": result[`Event Name`] ? result[`Event Name`] : "",
              Duration: result[`Duration`] ? result[`Duration`] : "",
              SC: result[`SC`] ? result[`SC`] : "",
              "Flight Phase": result[`Flight Phase`] ? result[`Flight Phase`] : "",
              Location: result[`Location`] ? result[`Location`] : "",
              Comment: result[`Comment`] ? result[`Comment`] : "",
              Parameter: result[`Parameter`] ? result[`Parameter`] : "",
              Min: result[`Min`] ? result[`Min`] : "",
              Max: result[`Max`] ? result[`Max`] : "",
              Average: result[`Average`] ? result[`Average`] : "",
              Remarks: result[`Remarks`] ? result[`Remarks`] : "",
              _rowVariant: result[`SC`] ? (result[`SC`] == 3 ? "red" : result[`SC`] == 2 ? "yellow" : result[`SC`] == 1 ? "green" : "") : ""
            };
          });
        });
      },
      toggleSelection(id) {
        if (this.selectedItems.includes(id)) {
          let indexOf = this.selectedItems.indexOf(id);
          this.selectedItems.splice(indexOf, 1);
        } else {
          this.selectedItems.push(id);
        }
      },
      updateFile() {
        this.tableData = this.tableData.map((item) => {
          delete item.selected;
          delete item._rowVariant;
          return item;
        });
        const payload = {
          reportID: this.reportId,
          fileName: this.fileName,
          payload: this.tableData
        };
        this.$axios.post("reports/getSheet", payload).then((res) => {
          if (res.status === 200) {
            this.$swal({
              title: "Success",
              text: "Report updated successfully",
              icon: "success",
              timer: 2000
            });
            this.isOpened = false;
          }
        });
      },
      deleteRows() {
        const data = this.tableData
          .filter((item, index) => !this.selectedItems.includes(index))
          .map((item) => {
            delete item.selected;
            delete item._rowVariant;

            return item;
          });

        const payload = {
          reportID: this.reportId,
          fileName: this.fileName,
          payload: data
        };

        this.loading = true;

        this.$axios
          .post("reports/getSheet", payload)
          .then(async (res) => {
            if (res.status === 200) {
              // wait for 2 seconds
              await new Promise((resolve) => setTimeout(resolve, 2000));
              await this.callApi(this.data.id);
              this.loading = false;

              this.$swal({
                title: "Success",
                text: `${this.selectedItems.length} rows deleted successfully`,
                icon: "success",
                timer: 3000
              });
              this.selectedItems = [];
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      pop() {
        this.$refs.deleteModal.open();
      }
    }
  };
</script>

<style>
  .table-green td {
    color: #007f00 !important;
  }
  .table-yellow td {
    color: #d8971a !important;
  }
  .table-red td {
    color: #ff0000 !important;
  }
  .reportModal {
    height: 700px;
    overflow: auto;
  }
</style>
